@charset "utf-8";
@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}

::selection{background:$baseColor;color:#fff;}
::-moz-selection{background:$baseColor;color:#fff;}

*{
	box-sizing: border-box;
}

html{
	position: relative;
	overflow-x: hidden;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	font-family: $fontBase;
	line-height: $lineHeight;
	letter-spacing: .1em;
	color: $textColor;
	background-color: #fff;
	overflow-x: hidden;
}
body#lower{
	background-color: #f1f1f1;
}

img,
svg{
	width: 100%;
	height: auto;
}

.inner{
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 1000px;
	padding: 0 20px;
	margin: auto;
}

.in{
	position: relative;
}

.pc{display: none !important;}
.smt{display: block !important;}


.h-lg,
.h-md,
.h-sm{
	font-family: $fontJa;
	font-weight: 600;
	line-height: 1.8;
	letter-spacing: .2em;
	span{
		display: inline-block;
	}
}
.h-lg{
	color: #BD5258;
	font-size: 22px;	
}
.h-md{
	font-size: 20px;
}
.h-sm{
	font-size: 18px;
}

.title{
	width: 30%;
	max-width: 200px;
}
.title.wide{
	width: 95%;
	max-width: 600px;
}

.tel{
	display: inline-block;
	width: 55%;
	address{
		display: block;
		font-size: 10px;
		margin: 0 0 5px;
		span{
			display: inline-block;
		}
	}
	a{
		img{
			max-width: 200px;
		}
	}
}
.tel.wide{
	width: 100%;
	address{
		font-size: 15px;
	}
	a{
		img{
			width: 80%;
			max-width: 510px;
		}
	}
}

.img-wrap{
	margin: auto;
	line-height: 0;
}

h1{
	position: absolute;
	top: 0px;
	left: 20px;
}
header#g-head{
	text-align: right;
	padding: 15px 0;
	background-color: #fff;
}


main{
	display: block;
}

.sec{
	text-align: center;
	padding: 40px 0;
	.sec-head{
		margin: 0 0 50px;
		p{
			margin: 10px 0 0;
		}
	}
	.sec-body{
		margin: 20px 0 0;
		p{
			span{
				display: inline-block;
			}
		}
	}
	.sec-foot{
		margin: 30px 0;
	}
}

.btns{
	a{
		position: relative;
		display: block;
		width: 100%;
		max-width: 560px;
		margin: auto;
		b{
			position: relative;
			z-index: 1;
			display: block;
			padding: 15px;
			color: $baseColor;
			font-size: 18px;
			font-family: $fontJa;
			font-weight: 800;
			border: 5px solid $baseColor;
			background-color: #FFEC00;
		}
		b:after{
			content: '';
			display: inline-block;
			border: 8px solid transparent;
			border-left: 12px solid $baseColor;
			margin-left: 5%;
		}
	}
	a:before{
		content: '';
		display: block;
		position: absolute;
		top: 7px;
		left: 7px;
		width: 100%;
		height: 100%;
		background: url(../img/bg_btn.png) no-repeat center;
		background-size: 100% 100%;
		transition: all .3s;
	}
	a:hover:before{
		top: 0;
		left: 0;
	}
}

footer#g-foot{
	text-align: center;
	padding: 20px 0 0;
	.title.wide{
		margin: auto;
	}
	.tel.wide{
		margin: 20px 0 0;
		a{
			img{
				margin: 10px 0 0;
			}
		}
		p{
			margin: 5px 0 0;
		}
	}
}

#a_access{
	width: 100%;
	margin: 40px 0 0;
	span{
		overflow: hidden;
		position: relative;
		display: block;
		width: 100%;
		height: 0;
		padding-top: 65%;
		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

#copyright{
	font-size: 11px;
	color: #fff;
	padding: 20px;
	background-color: $baseColor;
	small{
		display: inline-block;
	}
}


#lower-main-view{
	position: relative;
	> span{
		display: block;
		line-height: 0;
	}
	h2{
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: auto;
		color: #fff;
		transform: translate(-50%, -50%);
	}
}


@media screen and (min-width: 600px) {

	.h-lg{
		font-size: 28px;
	}
	.h-md{
		font-size: 26px;
	}
	.h-sm{
		font-size: 20px;
	}

	.btns{
		a{
			b{
				font-size: 20px;
			}
		}
	}
	
	footer{

	}
	#a_access{
		span{
			padding-top: 55%;
		}
	}
}


@media screen and (min-width: 800px) {
	
	body{
		font-size: 18px;
	}

	.h-lg{
		font-size: 36px;
	}
	.h-md{
		font-size: 32px;
	}
	.h-sm{
		font-size: 22px;
	}

	h1{
		left: 50%;
		transform: translateX(-50%);
	}

	header#g-head{
		padding: 20px 0;
	}

	.sec{
		padding: 70px 0;
		.sec-body{
			margin: 40px 0 0;
		}
		.sec-foot{
			margin: 50px 0;
		}
	}

	.btns{
		a{
			b{
				font-size: 22px;
			}
		}
	}

	footer{

	}
	#a_access{
		margin: 80px 0 0;
		span{
			padding-top: 45%;
		}
	}

}


@media screen and (min-width: 1000px) {
	.h-lg{
		font-size: 42px;
	}
	.h-md{
		font-size: 38px;
	}
	.h-sm{
		font-size: 24px;
	}

	.btns{
		a{
			b{
				font-size: 24px;
			}
		}
	}

	footer{

	}
	#a_access{
		span{
			padding-top: 35%;
		}
	}

}


@media screen and (min-width: 1200px) {
	footer{

	}
	#a_access{
		span{
			padding-top: 400px;
		}
	}

}