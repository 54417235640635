
$baseColor: #00802C; // green

$textColor: #1A1311;
$fontSize: 15px;
$lineHeight: 2;

/* font-family */
$fontBase: "M PLUS Rounded 1c";
$fontJa: 'Noto Sans Japanese', serif;
$fontGothic: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", 'Open Sans', YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
